var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"20px 70px"}},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"elFormDom",attrs:{"element-loading-text":"加载中…","label-width":"130px","label-position":"top","model":_vm.form.data,"rules":_vm.form.rules,"size":"small"}},[_c('el-row',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"prop":"kinds","label":"所需服务"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.kindListOpt,"props":{value:'id',label:'name', checkStrictly: false}},model:{value:(_vm.form.data.kinds),callback:function ($$v) {_vm.$set(_vm.form.data, "kinds", $$v)},expression:"form.data.kinds"}})],1)],1),_c('el-col',{attrs:{"span":8,"offset":10}},[_c('a',{attrs:{"href":_vm.table.url}},[_c('el-button',{staticStyle:{"background":"#EBF5FE","color":"#1966FF","margin-right":"10px"},attrs:{"type":"primary","icon":"el-icon-upload","size":"small"}},[_vm._v("下载需求表模板")])],1),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-upload","size":"small"},on:{"click":_vm.handleUpload}},[_vm._v("上传需求表")])],1)],1),(_vm.table.data.length > 0)?_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.table.data,"border":"","size":"small","header-cell-style":{background:'#E5F2FE'}},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"name","label":"联系人","width":"100"}}),_c('el-table-column',{attrs:{"prop":"phone","label":"电话","width":"120"}}),_c('el-table-column',{attrs:{"prop":"address","label":"服务地区","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.province)+_vm._s(row.city)+_vm._s(row.area))])]}}],null,false,1711153262)}),_c('el-table-column',{attrs:{"prop":"address","label":"详细地址","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"address","label":"服务周期","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.start_time)+"至"+_vm._s(row.end_time))])]}}],null,false,2595823153)}),_c('el-table-column',{attrs:{"prop":"price_type","label":"定价方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},row.price_type))+" ")]}}],null,false,1458869752)}),_c('el-table-column',{attrs:{"prop":"amount","label":"结算金额(元)"}}),_c('el-table-column',{attrs:{"prop":"content","label":"需求详情","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticStyle:{"color":"#F56C6C"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v(" 删除 ")])]}}],null,false,2425979360)})],1),(_vm.table.total > 10)?_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.table.params.count,"page-sizes":[10, 50, 100, 150],"page-size":10,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.table.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e(),_c('el-row',{staticStyle:{"margin-top":"20px"},attrs:{"type":"flex","justify":"center"}},[_c('el-button',{staticClass:"conBtn flash-hover",attrs:{"type":"primary","loading":_vm.form.loading,"disabled":_vm.form.loading},on:{"click":_vm.confirm}},[_vm._v("确认发布")])],1)],1):_c('div',{staticClass:"empty"},[_vm._v(" 请点击右上角按钮，上传需求表 ")])],1),_c('el-dialog',{attrs:{"title":"上传需求表","width":"500px","visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{attrs:{"label-width":"130px","label-position":"top","model":_vm.formUpload,"size":"small"}},[_c('el-form-item',{attrs:{"label":"选择文件"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"drag":"","limit":1,"action":"","headers":{token:_vm.token},"file-list":_vm.fileList,"on-remove":_vm.removeFile,"multiple":false,"http-request":_vm.httpRequest}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])])])],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.upload}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }