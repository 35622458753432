<template>
  <div class="handleWrap">
    <div class="flex-col relative section-handle">
      <div class="flex-row justify-between items-center group">
        <div class="flex-row items-center space-x-124">
          <el-image class="shrink-0 image" :src="require('@/assets/img/logo.png')"></el-image>
          <div class="group_2">
            <a :href="handleSkit('/order')" :class="tabIndex == 2 ? 'font_1 text border-b' : 'font_1 text' " @click="clickPush(2)">我的工单</a>
            <a :href="handleSkit('/created')" :class="tabIndex == 1 ? 'font_1 text border-b' : 'font_1 text'" @click="clickPush(1)">发布需求</a>
          </div>
        </div>
        <div class="flex-row items-center space-x-80">
          <div class="flex-row items-center">
            <el-popover
              style="width:240px;text-align:center;padding:12px;"
              placement="bottom"
              title=""
              trigger="click">
              <img style="width:120px;height:120px" :src="require('@/assets/img/code-ios1.png')" />
              <img style="width:120px;height:120px" :src="require('@/assets/img/code-android1.png')" />
              <div class="flex-row items-center space-x-11" slot="reference">
                <span class="font_2 text_3">下载吾有空App</span>
                <img
                  class="shrink-0 image_2"
                  :src="require('@/assets/img/dow.png')"
                />
              </div>
            </el-popover>

            <el-popover
              placement="bottom"
              width="120"
              trigger="click">
              <img style="width:120px;height:120px" :src="require('@/assets/img/code.png')" />
              <div class="flex-row items-center space-x-11" slot="reference">
                <span class="font_2 text_4">微信小程序</span>
                <img
                  class="shrink-0 image_2"
                  :src="require('@/assets/img/dow.png')"
                />
              </div>
            </el-popover>
          </div>

          <el-popover
            v-if="!!account"
            placement="bottom"
            trigger="click">
            <div style="width:100%;text-align:center;cursor: pointer;" @click="outlog">退出登录</div>
            <div style="display:flex;align-items: center; cursor: pointer;" slot="reference">
              <img
                style="width:32px;height:32px;margin-right:10px;"
                :src="require('@/assets/img/order/default.png')"
              />
              {{account}}
            </div>
          </el-popover>
          
          <div v-else class="flex-col justify-start items-center text-wrapper flash-hover" @click="login">立即登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStorage, clear } from "@/storage"
export default {
  name: 'PageHeader',
  data() {
    return {
      account: getStorage("account") || '',
      tabIndex: 1,
    }
  },
  methods: {
    login() {
      this.$router.push('/login')
    },
    outlog() {
      this.$confirm('请确认退出登录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        clear('token')
        this.$router.push('/login')
      })
    },
    clickPush(num, router) {
      console.log(router)
      this.tabIndex = num;
      this.$router.push(router)
    },
    handleSkit(url) {
      return `${url}`;
    }
  }
}
</script>
<style scoped>
.handleWrap {
  width: 100%;
  background: #fff;
  z-index: 999;
  position: sticky;
  top: 0;
}
.section-handle {
  width: 1320px;
  margin: 0 auto;
  background-color: #ffffff;
}
.group {
  /* padding: 19px 0 15px; */
  height: 64px;
}
.space-x-124 > *:not(:first-child) {
  margin-left: 100px;
}
.image {
  width: 150px;
  height: 40px;
}
.group_2 {
  line-height: 20px;
  height: 20px;
}
.group_2 a {
  text-decoration: none;
}
.font_1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 20px;
  color: #323232;
}
.text {
  letter-spacing: 2px;
  height: 42px;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
.text:hover {
  /* color: #1d7bfc;
  border-bottom: solid 4px #1d7bfc; */
}
.space-x-80 > *:not(:first-child) {
  margin-left: 80px;
}
.space-x-60 > *:not(:first-child) {
  margin-left: 60px;
}
.space-x-11 > *:not(:first-child) {
  margin-left: 11px;
}
.font_2 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  color: #333333;
}
.text_3 {
  line-height: 19px;
  cursor: pointer;
}
.image_2 {
  width: 12px;
  height: 7px;
}
.text_4 {
  line-height: 17px;
  cursor: pointer;
}
.text-wrapper {
  padding: 12px 0;
  background-color: #1d7bfc;
  border-radius: 6px;
  width: 100px;
  height: 40px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #ffffff;
  line-height: 16px;
  cursor: pointer;
}
.border-b {
  border-bottom: solid 4px #1d7bfc;
  color: #1d7bfc;
}
</style>