<template>
  <div>
    <Header></Header>
      <div class="main__wrap" style="">
        <div class="creater-wrap">
          <div class="top">
            <div :class="activePage == 'Section1' ? 'text1 active' : 'text1'" @click="handleTab('Section1')">发布单条需求</div>
            <div :class="activePage == 'Section2' ? 'text1 active' : 'text1'" @click="handleTab('Section2')">发布批量需求</div>
            <span style="color:#E52525;" v-if="activePage == 'Section2'">（当前仅支持同一服务类型批量发布）</span>
          </div>
          <Section1 v-if="activePage == 'Section1'" :kindListOpt="kindListOpt"></Section1>
          <Section2 v-if="activePage == 'Section2'" :kindListOpt="kindListOpt2"></Section2>
        </div>
      </div>
  </div>
</template>

<script>
import Header from './header.vue'
// import Header from '../home/header.vue'
import Section1 from './section1.vue'
import Section2 from './section2.vue'
export default {
  name: 'OrderName',
  components: {
    Header,
    Section1,
    Section2,
  },
  data() {
    return {
      activePage: 'Section1',
      kindListOpt: [],
      kindListOpt2: [],
    }
  },
  created() {
    this.getkindList();
  },
  methods: {
    handleTab(type) {
      this.activePage = type
      if(this.activePage == 'Section2') {
        // （陪诊师、做饭阿姨、多人接单模式）禁用， 不能批量发布需求
        this.kindListOpt2 = this.kindListOpt.map(item => {
          if(item.id == 189) { // id：189陪诊师
            return {
              ...item,
              children:item.children.map(v => {
                return {
                  ...v,
                  disabled: true,
                }
              }),
            }
          } else if( item.id == 127 ) { // id: 127家政保洁
            return {
              ...item,
              children:item.children.map(v => {
                if(v.id == 183) { // id:183 做饭阿姨
                  return {
                    ...v,
                    disabled: true,
                  }
                }else {
                  return {
                    ...v,
                  }
                }
              }),
            }
          } else if(item.is_multi == 1) { // is_multi 1:多人接单，2：单人接单
            return {
              ...item,
              children:item.children.map(v => {
                return {
                  ...v,
                  disabled: true,
                }
              }),
            }
          } else {
            return {
              ...item,
            }
          }
        })
      }
    },
    // 获取服务分类
    getkindList() {
      this.$http.get('/user/flex_index/kindList',{params:{}}).then(res => {
        if(res.code == 1) {
          this.kindListOpt = res.data.list;
        }
      })
    },
  }
}
</script>

<style scoped>
.main__wrap {
  background: #F5F5F5;
  padding: 20px 0;
}
.creater-wrap {
  width: 1320px;
  margin: 0 auto;
  max-height: 150vh;
  min-height: 85vh;
  background: #fff;
  border-radius: 10px;
}
.top {
  height: 60px;
  line-height: 60px;
  display: flex;
  padding: 0 40px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
}
.text1:first-child {
  margin-right: 60px;
}
.active {
  border-bottom: solid 3px #3690FC;
  color: #3690FC;
}
</style>