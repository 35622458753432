<template>
  <div style="margin: 20px 70px;">
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="top"
      :model="form.data"
      :rules="form.rules"
      size="small"
    >
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item prop="kinds" label="所需服务">
            <el-cascader
              style="width:100%;"
              ref="cascaderKinds"
              v-model="form.data.kinds"
              :options="kindListOpt"
              :props="{value:'id',label:'name', checkStrictly: false}"
              @change="handleChangeKinds"></el-cascader>
          </el-form-item>
        </el-col>
        <!-- 陪诊服务：就诊时间 190:陪诊,191:代取报告,192：跑腿/买药 183:做饭-->
        <el-col :span="6" v-if="[...form.data.kinds].pop() == 190">
          <el-form-item prop="pz_time" label="就诊时间">
            <el-date-picker
              v-model="form.data.pz_time"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm"
              style="width:100%"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <!-- 代买药：代买时间  -->
        <el-col :span="6" v-else-if="[...form.data.kinds].pop() == 192">
          <el-form-item prop="pz_time" label="代买时间">
            <el-date-picker
              v-model="form.data.pz_time"
              type="date"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd"
              style="width:100%"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <!-- 上门做饭服：服务时间 -->
        <el-col :span="6" v-else-if="[...form.data.kinds].pop() == 183">
          <el-form-item prop="food_time" label="服务时间">
            <el-date-picker
              v-model="form.data.food_time"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm"
              style="width:100%"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <!-- 其他类型都是服务周期 -->
        <el-col :span="6" v-else>
          <el-form-item prop="time" label="服务周期">
            <el-date-picker
              style="width:100%"
              v-model="form.data.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <!-- 若选择的分类有规格 则展示对方报价和平台专享价 -->
        <el-col :span="8" v-if="is_item == 1 && [...form.data.spec_id].pop() != 0">
          <el-form-item prop="price_type" label="定价方式">
            <el-radio :label="3" v-model="form.data.price_type">平台专享价</el-radio>
            <el-radio :label="2" v-model="form.data.price_type">对方报价</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-else>
          <el-form-item prop="price_type" label="定价方式">
            <el-radio :label="1" v-model="form.data.price_type">自主报价</el-radio>
            <el-radio :label="2" v-model="form.data.price_type">对方报价</el-radio>
          </el-form-item>
        </el-col>
        <!-- 自主报价时显示金额, -->
        <el-col :span="4" v-if="form.data.price_type == 1 || form.data.price_type == 3 ">
          <el-form-item prop="amount" label="结算金额">
            <el-input
              v-model="form.data.amount"
              placeholder="请输入结算金额（≥1）"
              :disabled="form.data.price_type == 3 ? true : false"
            > 
              <!-- 多人模式并且用工人数大于1时单位 元/人 -->
              <span slot="suffix">{{(is_multi == 1 && form.data.use_num > 1 ) ? '元/人' : '元'}}</span>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- //接单模式 1:多人 2:单人 -->
        <el-col :span="6" v-if="is_multi == 1">
          <el-form-item prop="use_num" label="用工人数">
            <el-input
              v-model="form.data.use_num"
              placeholder="请输入用工人数"
              @input="form.data.use_num=form.data.use_num.replace(/^(0+)|[^\d]+/g,'')"
            >
              <span slot="suffix">人</span>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- 若选择的二级分类有规格is_item == 1，则展示规格 -->
        <el-col :span="6" v-if="is_item == 1 ">
          <el-form-item prop="spec_id" label="规格" >
            <el-cascader
              v-model="form.data.spec_id"
              style="width:100%;"
              ref="cascaderspec"
              :options="itemOpt"
              :props="{value:'id',label:'name', checkStrictly: false}"
              @focus="getItem"
              @change="handleChangeItem"></el-cascader>
          </el-form-item>
        </el-col>
        <!-- 当所需服务选择 “陪诊师” 时显示 -->
        <el-col :span="12" v-if="[...form.data.kinds].pop() == 191 || [...form.data.kinds].pop() == 192">
          <el-form-item prop="pz_hospital" label="医院/购买 地址">
            <el-row>
              <el-col :span="12">
                <el-cascader
                  style="width:100%;"
                  v-model="form.data.areaarr2"
                  :options="areaOpt"
                  :props="{ checkStrictly: false}"
                  @change="changeArea">
                </el-cascader>
              </el-col>
              <el-col :span="12">
                <el-input
                  v-model="form.data.pz_hospital"
                  placeholder="请输入路名及医院名称"/>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <template v-if="[...form.data.kinds].pop() == 190">
          <el-col :span="12">
            <el-form-item prop="pz_hospital" label="就诊医院">
              <el-row>
                <el-col :span="12">
                  <el-cascader
                    style="width:100%;"
                    v-model="form.data.areaarr2"
                    :options="areaOpt"
                    :props="{ checkStrictly: false}"
                    @change="changeArea">
                  </el-cascader>
                </el-col>
                <el-col :span="12">
                  <el-input
                    v-model="form.data.pz_hospital"
                    placeholder="请输入路名及医院名称"/>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="pz_dep" label="就诊科室">
              <el-input
                v-model="form.data.pz_dep"
                placeholder="如：消化内科"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="pz_serve_name" label="就诊人">
              <el-input
                placeholder="请选择就诊人"
                suffix-icon="el-icon-edit"
                v-model="form.data.pz_serve_name"
                @focus="handlePatien">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="pz_duration" label="需要陪诊时间">
              <el-radio :label="1" v-model="form.data.pz_duration">半天</el-radio>
              <el-radio :label="2" v-model="form.data.pz_duration">一天</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="pz_serve" label="是否需要接送">
              <el-radio :label="1" v-model="form.data.pz_serve">是</el-radio>
              <el-radio :label="2" v-model="form.data.pz_serve">否</el-radio>
            </el-form-item>
          </el-col>
        </template>
        
        <!-- 当所需服务选择 "上门做饭" 时显示 -->
        <template  v-if="[...form.data.kinds].pop() == 183">
          <el-col :span="7" >
            <el-form-item prop="food_num" label="做菜数量">
              <el-input
                v-model="form.data.food_num"
                placeholder="请输入数字"
                @input="form.data.food_num=form.data.food_num.replace(/\D|^0/g,'')"
              >
                <span slot="suffix">个</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="food_flavor" label="菜系口味">
              <el-select v-model="form.data.food_flavor" style="width:100%;" @focus="handleFood">
                <el-option v-for="item in footOption"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="food_buy" label="需要买菜">
              <template slot="label">
                <span>需要买菜</span>
                <span style="font-size:12px; color:#1966FF;">（买菜费用凭购买小票为对方报销）</span>
              </template>
              <el-radio :label="1" v-model="form.data.food_buy">是</el-radio>
              <el-radio :label="2" v-model="form.data.food_buy">否</el-radio>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
      <!-- "pz_serve": 1, //接送服务 1:是 2:否 -->
      <div class="address-bg" v-if="this.form.data.pz_serve != 2">
        <el-row>
          <el-col :span="22">
            <el-input
              v-model="discernText"
              clearable
              placeholder="输入或粘贴文字 即可自动填入联系人电话和地址（示例：吾先生，18888888888，江西省南昌市红谷滩区**路**号）"
            ></el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="handleDiscern">智能识别</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item prop="name" label="联系人">
              <el-input
                v-model="form.data.name"
                clearable
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="phone" label="联系电话">
              <el-input
                v-model="form.data.phone"
                clearable
                maxlength="11"
                placeholder="请输入电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="areaarr" label="服务地址">
              <el-cascader
                style="width:100%;"
                v-model="form.data.areaarr"
                :options="areaOpt"
                :props="{ checkStrictly: false}"
                @change="changeArea">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="address" label="详细地址">
              <el-input
                v-model="form.data.address"
                clearable
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="content" label="需求详情（0/500字）">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8}"
              placeholder="请详细描述您所遇到的问题以及需要的服务，描述的越详细，工人师傅了解后能提升报名（价）率！"
              v-model="form.data.content">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="" label="图片（限6张）（可上传需施工的场地图片，师傅能更好地了解情况）">
            <el-upload
              action=""
              :limit="6"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="httpRequestBack"
              accept="image/jpeg, image/png, image/gif">
              <img style="104px;height:104px;" :src="require('@/assets/img/upload.png')" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" style="padding-bottom:20px">
        <el-button
          class="conBtn flash-hover"
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确认发布</el-button
        >
      </el-row>
    </el-form>

    <!-- 选择就诊人 -->
    <el-dialog title="选择就诊人" center width="500px" height="500px" :visible.sync="outerVisible">
      <div>
        <div class="list" v-for="(item,index) in userList" :key="index" @click="handleUser(item)">
          <div class="list-text">
            <div>
              <span class="name">{{item.name}}</span>
              <span>{{item.relation_str}}</span>
            </div>
            <span>{{item.mobile}}</span>
          </div>
          <div class="idCade">
            <span>身份证号：{{item.id_card}}</span>
            <span>
              <el-button type="primary" size="mini" icon="el-icon-delete" circle slot="reference" @click.stop="handleDel(item)"></el-button>
            </span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="innerVisible = true">添加就诊人</el-button>
      </div>
    </el-dialog>

    <!-- 添加就诊人 -->
    <el-dialog
      width="500px"
      height="500px"
      title="添加就诊人"
      :visible.sync="innerVisible"
      append-to-body
      :destroy-on-close="true">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
        <el-form-item label="就 诊 人" prop="name">
          <el-input v-model="ruleForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="ruleForm.sex" :label="1">男</el-radio>
          <el-radio v-model="ruleForm.sex" :label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input v-model="ruleForm.mobile" maxlength="11" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_card">
          <el-input v-model="ruleForm.id_card" maxlength="18" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="与就诊人关系" prop="relation">
          <el-select v-model="ruleForm.relation" placeholder="请选择" style="width:100%;" @focus="getRelation">
            <el-option v-for="item in relationOpt" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 支付 -->
    <order-pay v-if="payObj.show" ref="pay" :pay="payObj" @closePay="closePay"></order-pay>
    
  </div>
</template>

<script>
import OrderPay from '@/components/OrderPay.vue'
import { getStorage } from "@/storage"
import { REG_EXP } from '@/util'
export default {
  name: 'CreatedName',
  props:['kindListOpt'],
  components: {
    OrderPay
  },
  data() {
    return {
      token: getStorage('token'),
      loading: false,
      payObj: {
        show: false,
        code_url: '', // 支付二维码
        job_id: '', // 工单id
        order_no: '', // 订单编号
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      discernText: '', // 敏敏特穆尔;18807093042;江西省南昌市红谷滩区双子塔a1-44层
      form: {
        loading: false,
        data: {
          kinds: '', //二级工种ID
          start_time: "", //开始时间
          end_time: "", //结束时间
          phone: "", //联系电话
          name: "", //联系人
          province: '', //省
          city: '', //市
          area: '', //区
          price_type: 1, //定价方式 1:自主定价 2:对方报价
          amount: '', //金额 自主报价时必传
          content: "", //内容
          image: "",
          address: '',
          areaarr: [],
          time: [],
          food_num: "", //菜数
          food_flavor: "", //菜系
          food_buy: 1, //是否要买菜
          food_time: "", //做饭服务时间
          pz_hospital: "", //医院
          pz_dep: "", //科室
          pz_time: "", //陪诊时间、代取时间 、代买时间
          pz_serve: 1, //接送服务 1:是 2:否
          pz_serve_id: "", //就诊人ID
          pz_serve_name: "", //就诊人姓名
          pz_duration: 1, //陪诊时长 1:半天 2:一天
          pz_address: "", //代卖地址
          use_num: 1, // 用工人数
          spec_id: '', //规格ID 
        },
        rules: {
          kinds: [{ required: true, message:'必选项', trigger: 'change' }],
          phone: [
            { required: true, message:'必填项', trigger: 'change' },
            { pattern: REG_EXP.mobilePhone,message: "手机号格式不正确", trigger: "change"}
          ],
          name: [{ required: true, message:'必填项', trigger: 'change' }],
          price_type: [{ required: true, message:'必填项', trigger: 'change' }],
          amount: [
            { required: true, message:'必填项', trigger: ['change','blur'] },
            { pattern: REG_EXP.money, message: "请填写正确的金额格式，如：188.88", trigger: "blur"}
          ],
          content: [{ required: true, message:'必填项', trigger: 'change' }],
          areaarr: [{ required: true, message:'必填项', trigger: ['change', 'blur'] }],
          address: [{ required: true, message:'必填项', trigger: 'change' }],
          time: [{ required: true, message:'必填项', trigger: ['change','blur'] }],

          pz_hospital: [{ required: true, message:'必填项', trigger: 'change' }],
          pz_dep: [{ required: true, message:'必填项', trigger: 'change' }],
          pz_serve_name: [{ required: true, message:'必填项', trigger: 'change' }],
          pz_serve: [{ required: true, message:'必填项', trigger: 'change' }],
          pz_duration: [{ required: true, message:'必填项', trigger: 'change' }],
          pz_time: [{ required: true, message:'必填项', trigger: ['change','blur'] }],
          use_num: [{ required: true, message:'必填项', trigger: ['change','blur'] }],
          spec_id: [{ required: true, message:'必填项', trigger: ['change','blur'] }],

        },
      },
      
      areaOpt:[],
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: [],
      isShow: true,

      outerVisible: false,
      innerVisible: false,
      ruleForm: {
        name: '',
        phone: '',
        sex:1,
        icCade: '',
        relation: '',
      },
      rules: {
        name: [{ required: true, message:'必填项', trigger: 'change' }],
        sex: [{ required: true, message:'必填项', trigger: ['change','blur'] }],
        mobile: [
          { required: true, message:'必填项', trigger: 'change' },
          { pattern: REG_EXP.mobilePhone,message: "手机号格式不正确", trigger: "change"}
        ],
        id_card: [
          { pattern: REG_EXP.identityCard, message: "身份证号格式不正确", trigger: "change"}
        ],
        relation: [{ required: true, message:'必填项', trigger: 'change' }],
      },
      userList: [], // 就诊人
      relationOpt: [], // 就诊人关系
      footOption: [], // 菜系
      itemOpt:[], // 规格项目
      is_multi: '', // 接单模式 1:多人 2:单人
      is_item: '', // 二级菜单是否有设置规格 0：未设置规格，1：有设置规格
    }
  },
  created() {
    this.getArea();
  },
  methods: {
    // 获取省、市、区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaOpt = res.data.list;
        }
      })
    },
    // 获取就诊人关系
    getRelation() {
      this.$http.post('/common/constant/relation',{}).then(res => {
        if(res.code == 1) {
          this.relationOpt = res.data;
        }
      })
    },
    // 获取规格项目
    getItem() {
      let _params = {
        page:1,
        count:10000,
        kinds:[...this.form.data.kinds].pop()
      }
      this.$http.get('/user/flex_job/getItem',{params:_params}).then(res => {
        if(res.code == 1) {
          let arr = res.data.list;
          if(arr && arr.length > 0) {
            this.itemOpt = arr.map(v => {
              return {
                ...v,
                children:v.spec.map(j => {
                  return {
                    ...j,
                    name:j.spec,
                  }
                })
              }
            })
          }
          // 规格中要自动生成一个其他，若选择了其他则没有定价，同原来报名/报价流程
          let str = {
            id: 0,
            name: '其他',
            children:[
              {
                amount: '',
                id: 0,
                name: '其他',
              }
            ],
          }
          this.itemOpt.push(str)
          // this.itemOpt.map(a => {
          //   return  a.children.push(str)
          // })
          console.log(this.itemOpt)

        }
      })
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          if(this.form.data.amount < 1 && this.form.data.price_type == 1) {
            return this.$message.warning('结算金额应（≥1）')
          }
          this.form.data.image = [];
          this.fileList.forEach(v => {
            this.form.data.image.push(v.url)
          })
          // 陪诊服务，如果不需要接送时，省市区需要赋医院地址
          if(this.form.data.pz_serve == 2) {
            this.form.data.province = this.form.data.areaarr2[0]
            this.form.data.city = this.form.data.areaarr2[1]
            this.form.data.area = this.form.data.areaarr2[2]
            this.form.data.address = this.form.data.pz_hospital;
          }
          let _params = {
            kinds: [...this.form.data.kinds].pop(),
            start_time: this.form.data.time[0],
            end_time: this.form.data.time[1],
            phone: this.form.data.phone,
            name: this.form.data.name,
            province: this.form.data.province,
            city: this.form.data.city,
            area: this.form.data.area,
            price_type: this.form.data.price_type,
            amount: this.form.data.amount,
            content: this.form.data.content,
            image: this.form.data.image.join("|"),
            address: this.form.data.address,
            // 做饭
            food_num: this.form.data.food_num,
            food_flavor: this.form.data.food_flavor,
            food_buy: this.form.data.food_buy,
            food_time: this.form.data.food_time,
            // 陪诊
            pz_hospital: this.form.data.pz_hospital,
            pz_dep: this.form.data.pz_dep,
            pz_time: this.form.data.pz_time,
            pz_serve: this.form.data.pz_serve,
            pz_serve_id: this.form.data.pz_serve_id,
            pz_duration: this.form.data.pz_duration,
            pz_address: this.form.data.pz_hospital, // 跑腿代买地址
            use_num: this.is_multi == 1 ? this.form.data.use_num : '', // 选中服务若是多人模式才有用工人数
            spec_id: [...this.form.data.spec_id].pop()// 规格
          }
          this.$http.post('/user/web/addJob', _params).then(res => {
            if(res.code == 1) {
              // 只有平台专享价时才有支付
              if(this.is_item == 1 && this.form.data.price_type == 3) {
                // appid: "wx443f439ee479b0e6"
                // code_url: "weixin://wxpay/bizpayurl?pr=JSaoiFBzz"
                // id: "966"
                // mch_id: "1624005536"
                // nonce_str: "4zobUiO7RGsxy8N1"
                // order_no: "568281865635495936"
                // prepay_id: "wx30164323282044d4f46983e436ee680000"
                // result_code: "SUCCESS"
                // return_code: "SUCCESS"
                // return_msg: "OK"
                // sign: "D568CEBE2C2A04198D39E1609848C9EC"
                // trade_type: "NATIVE"

                // 将订单参数传递给支付组件
                this.payObj.show = true;
                this.payObj.code_url = res.data.code_url;
                this.payObj.job_id = res.data.id; // 工单id
                this.payObj.order_no = res.data.order_no; // 订单编号
              } else {
                this.$message.success('发布成功！')
                this.$router.push('/order')
              }
            }
          })
        }
      })
    },
    // 识别
    handleDiscern() {
      if(!!this.discernText) {
        this.$http.post('/user/web/discern', {address: this.discernText}).then(res => {
          if(res.code == 1) {
            console.log(res.data)
            this.form.data.name = res.data.name;
            this.form.data.phone = res.data.mobile;
            this.form.data.areaarr = [res.data.province,res.data.city,res.data.area];
            this.form.data.address = res.data.other_address;
            this.form.data.province = res.data.province;
            this.form.data.city = res.data.city;
            this.form.data.area = res.data.area;
          }
        })
      }
    },
    // 上传
    httpRequestBack(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
        if(res.code === 1) {
          let str = {
            url: res.data.all_path_url,
            name: file.file.name,
            uid: file.file.uid
          }
          this.fileList.push(str);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleRemove(file) {
      this.fileList.forEach((v,i) => {
        if(file.uid == v.uid) {
          this.fileList.splice(i, 1)
        }
      })
      this.fileList = [];
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 现在地址
    changeArea(value) {
      console.log(value)
      this.form.data.province = value[0];
      this.form.data.city = value[1];
      this.form.data.area = value[2];
    },
    // 选择就诊人弹窗
    handlePatien() {
      this.outerVisible = true;
      this.getUser();
    },
    // 获取就诊人列表
    getUser() {
      this.$http.get('/user/flex_medical/list',{params:{page:1,count:1000}}).then(res => {
        if(res.code == 1) {
          this.userList = res.data.list;
        }
      })
    },
    // 删除就诊人
    handleDel(row) {
      this.$confirm('请确认删除操作？','提示',{
        confirmButtonText:'确认',
        cancelButtonText: '取消',
        type:'warning'
      }).then(() => {
        this.$http.post('/user/flex_medical/delete',{id:row.id}).then(res => {
          if(res.code == 1) {
            this.$message.success('删除成功!');
            this.getUser();
          }
        })
      }).catch(() => {})
    },
    // 添加就诊人
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if(valid) {
          this.$http.post('/user/flex_medical/add', this.ruleForm).then(res => {
            if(res.code == 1) {
              this.$message.success('添加就诊人成功！')
              this.innerVisible = false;
              this.$refs.ruleForm.resetFields();
              this.getUser();
            }
          })
        }
      })
    },
    // 选择就诊人
    handleUser(item) {
      this.form.data.pz_serve_id = item.id;
      this.form.data.pz_serve_name = item.name;
      this.outerVisible = false; 
    },
    // 选择菜系
    handleFood() {
      this.$http.post('/common/constant/foot',{}).then(res => {
        if(res.code == 1) {
          this.footOption = res.data;
        }
      })
    },
    // 字符转译
    toStr(data, key) {
      return data[key]
    },
    // 选中分类
    handleChangeKinds() {
      // 获取选中的二级分类 is_multi （ //接单模式 1:多人 2:单人）
      this.is_multi = this.$refs.cascaderKinds.getCheckedNodes()[0].data.is_multi;
      // 获取选中的二级分类 is_item （ //是否有规格 0:未设置规格 1:设置了规格）
      this.is_item = this.$refs.cascaderKinds.getCheckedNodes()[0].data.is_item;
      this.itemOpt = []
      this.form.data.amount = ''; // 清除金额
      
    },
    // 选择规格
    handleChangeItem(value) {
      console.log(value)
      if([...value].pop() == 0) {
        this.form.data.price_type = 1; // 若选择其他规格，定价方式模认为自主定价
      } else {
        this.form.data.price_type = 3; // 若选择了规格，定价方式模认为平台专享价
      }
      // 获取取规格的金额
      this.form.data.amount = this.$refs.cascaderspec.getCheckedNodes()[0].data.amount;
      
    },
    // 关闭支付弹窗
    closePay() {
      console.log(8888)
      this.payObj.show = false;
    }
  }
}
</script>

<style scoped>
.flash-hover {
	position: relative;
	overflow: hidden
}
.flash-hover:hover::after {
	left: 100%
}
.flash-hover::after {
	content: "";
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	display: block;
	height: 100%;
	background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#fff), to(transparent));
	background: linear-gradient(90deg, transparent, #fff, transparent);
	-webkit-transition: .5s;
	transition: .5s
}

.address-bg {
  background: #F9FCFF;
  padding: 20px;
  border: solid 1px #ddd;
}
.upload-demo {
  width: 100px;
  height: 100px;
}
.conBtn {
  width: 180px;
  height: 50px;
  font-size: 18px;
  border: #4785ff;
}
.list {
  padding: 10px;
  border-bottom: solid 1px #F9FCFF;
}
.list:hover {
  background: #F9FCFF;
  cursor: pointer;
  box-shadow:0 2px 12px 0 rgb(0 0 0 / 10%);
  border: none;
}
.list-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.list-text .name {
  margin-right: 30px;
  font-size: 16px;
  font-weight: bold;
}
.idCade {
  display: flex;
  justify-content: space-between;
}
</style>
<style>
.el-form-item {
  margin-bottom: 18px;
}
.el-upload--picture-card {
  border: none;
  width: 104px;
  height: 104px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 104px;
  height: 104px;
}
.el-range-editor--small .el-range-separator {
  width: 20px;
}
</style>