<template>
  <div style="margin: 20px 70px;">
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="top"
      :model="form.data"
      :rules="form.rules"
      size="small"
    >
      <el-row :gutter="20" style="display:flex;align-items:center">
        <el-col :span="8">
          <el-form-item prop="kinds" label="所需服务">
            <el-cascader
              style="width:100%;"
              v-model="form.data.kinds"
              :options="kindListOpt"
              :props="{value:'id',label:'name', checkStrictly: false}"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="10">
          <a :href="table.url"><el-button type="primary" icon="el-icon-upload" size="small" style="background: #EBF5FE;color: #1966FF;margin-right:10px">下载需求表模板</el-button></a>
          <el-button type="primary" icon="el-icon-upload" size="small" @click="handleUpload">上传需求表</el-button>
        </el-col>
      </el-row>
      <div v-if="table.data.length > 0">
        <el-table
          :data="table.data"
          border
          style="width: 100%"
          size="small"
          :header-cell-style="{background:'#E5F2FE'}"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="name"
            label="联系人"
            width="100">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="电话"
            width="120">
          </el-table-column>
          <el-table-column
            prop="address"
            label="服务地区"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              <span>{{row.province}}{{row.city}}{{row.area}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="详细地址"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="address"
            label="服务周期"
            width="180">
            <template slot-scope="{row}">
              <span>{{row.start_time}}至{{row.end_time}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="price_type"
            label="定价方式">
            <template slot-scope="{row}">
             {{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},row.price_type)}} 
            </template>
          </el-table-column>
          <el-table-column
            prop="amount"
            label="结算金额(元)">
          </el-table-column>
          <el-table-column
            prop="content"
            label="需求详情"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120">
            <template slot-scope="{row}">
              <el-button
                @click="handleDelete(row)"
                type="text"
                size="small"
                style="color:#F56C6C">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination" v-if="table.total > 10">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="table.params.count"
            :page-sizes="[10, 50, 100, 150]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="table.total">
          </el-pagination>
        </div>
        <el-row type="flex" justify="center" style="margin-top:20px">
          <el-button
            class="conBtn flash-hover"
            type="primary"
            :loading="form.loading"
            :disabled="form.loading"
            @click="confirm"
            >确认发布</el-button
          >
        </el-row>
      </div>
      <div class="empty" v-else>
        请点击右上角按钮，上传需求表
      </div>
    </el-form>

    <el-dialog title="上传需求表"  width="500px" :visible.sync="dialogFormVisible">
      <el-form 
        label-width="130px"
        label-position="top"
        :model="formUpload"
        size="small">
        <el-form-item label="选择文件">
          <el-upload
            class="upload-demo"
            drag
            :limit="1"
            action=""
            :headers="{token:token}"
            :file-list="fileList"
            :on-remove="removeFile"
            :multiple="false"
            :http-request="httpRequest"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="upload">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getStorage } from "@/storage"
export default {
  name: 'Section2',
  props:['kindListOpt'],
  data() {
    return {
      token: getStorage('token'),
      loading: false,
      formUpload: {},
      form: {
        loading: false,
        data: {
          kinds: '',
        },
        rules: {
          kinds: [{ required: true, message:'必填项', trigger: 'change' }],
        }
      },
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        url: '',
      },
      dialogFormVisible: false,
      fileList: [],
      multipleSelection: [],
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.post('/user/web/waitList', this.table.params).then(res => {
        if(res.code == 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.url = res.data.url;
        }
      })
    },
    // pageSize 改变时会触发
    handleSizeChange(pageSize) {
      this.table.params.count = pageSize;
      this.table.params.page = 1;
      this.getList();
    },
    //页面改变时触发
    handleCurrentChange(page) {
      this.table.params.page = page;
      this.getList();
    },
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 上传需求表
    handleUpload() {
      this.dialogFormVisible = true;
    },
    httpRequest(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('/user/web/upload', {formData, type:'upload'}).then(res => {
        if(res.data == 1) {
          
        }
      })
    },
    upload() {
      this.dialogFormVisible = false;
      this.getList()
    },
    // 发布
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          var _ids = ''
          // 如有多选传选择的数据，否则传所有
          if(this.multipleSelection.length > 0) {
            _ids = this.multipleSelection.map(v => {
              return v.id
            }).join("|")
          } else {
            _ids = this.table.data.map(v => {
              return v.id
            }).join("|")
          }
          let _params = {
            kinds: this.form.data.kinds.pop(),
            ids: _ids
          }
          this.$http.post('/user/web/release',_params).then(res => {
            if(res.code == 1) {
              this.$message.success('操作成功！')
              this.$router.push('/order')
            }
          })
        }
      })
    },
    removeFile() {
      this.fileList = [];
    },
    handleDelete(row) {
      this.$http.post('/user/web/delete',{id:row.id}).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          this.getList()
        }
      })
    },
    toStr(data, key) {
      return data[key]
    }
  }
}
</script>

<style >
.el-table--border .el-table__cell {
  border-right: none;
}
.conBtn {
  width: 180px;
  height: 50px;
  font-size: 18px;
  border: #4785ff;
}
.empty {
  width: 100%;
  height: 180px;
  background: #F9FCFF;
  border: solid 1px #e5e5e5;
  line-height: 180px;
  text-align: center;
  color: #91A8BD;
}
.pagination {
  margin: 10px 0;
  text-align: right;
}
</style>